@import "ui/styles/mixins";
.container {
  padding: 0;
  margin: 72px auto 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'mobileHeader mobileHeader'
    'reading reading'
    'goals goals'
    'feed feed';
  @media (min-width: 600px) {
    margin-top: 0;
  }
  @media (min-width: 900px) {
    max-width: initial;
    margin: 0;
    grid-template-columns: 40% 50%;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'reading feed'
      'goals feed'
      'explore feed'
      'explore feed';
  }
  @media (min-width: 900px) {
    max-width: initial;
    margin: 0;
    column-gap: 10%;
    margin-top: var(--size-1);
    padding: 0 20px;
  }
  @media (min-width: 1180px) {
    padding: 0;
  }
}

.readingArea {
  grid-area: reading;
  width: 100%;
  padding: 0 var(--size-5);
  @media (min-width: 600px) {
    padding: 0;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
}

.goalsArea {
  grid-area: goals;
  width: 100%;
  padding: 0 var(--size-5);
  @media (min-width: 600px) {
    padding: 0;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
}

.exploreArea {
  display: none;
  grid-area: explore;
  @media (min-width: 900px) {
    width: 100%;
    display: block;
    padding: 0;
    max-width: 560px;
    margin: 0 auto;
    height: 500px;

    position: sticky;
    top: 50px;
  }
}

.feedArea {
  width: 100%;
  grid-area: feed;
  padding: 0 20px;
  @media (min-width: 600px) {
    padding: 0;
    max-width: 560px;
    margin: 0 auto;
  }
}

.membersArea {
  grid-area: members;
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
}
.invitesArea {
  grid-area: invites;
  display: none;
  @media (min-width: 600px) {
    display: block;
    max-width: 560px;
    margin: 0 auto;
  }
}

.sidebarFooter {
  display: none;
  grid-area: sideFooter;
  height: 300px;
  position: sticky;
  top: 50px;
  @media (min-width: 900px) {
    display: block;
  }
}

.mobileHeader {
  grid-area: mobileHeader;
  @media (min-width: 600px) {
    display: none;
  }
}
