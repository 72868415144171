@import "ui/styles/mixins";
.book {
  user-select: none;
  border: var(--outlineWidth) solid var(--uiOutline);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-4) var(--size-5);
  --isHover: 0;
  position: relative;

  z-index: 0;
  transition: border-color 200ms ease;
  @media (hover: hover) {
    &:hover,
    &:focus {
      border-color: var(--uiInput);
      z-index: 1;
      --isHover: 1;
    }
  }
  @media (min-width: 600px) {
    padding: var(--size-5) var(--size-5);
  }

  h4 {
    font-weight: 400;
    font-size: 14px;
  }
}

.inner {
  flex: 1 0 0;
  display: flex;
  align-items: center;
}

.info {
  flex: 1 0 0;
}

.controls {
  display: flex;
  align-items: center;
  --isHover: 1;
}

.image {
  width: 40px;
  flex: 0 0 40px;
  align-self: flex-start;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--size-6);
  transition: opacity 300ms ease;
  transition: transform 300ms ease;
  @media (hover: hover) {
    transform: translateY(calc(var(--isHover) * -1px));
    -webkit-transform: translate3d(0, calc(var(--isHover) * -1px), 0); // Safari suff
  }
  @media (min-width: 600px) {
    margin-right: 23px;
  }
}

.authors {
  color: var(--textDiscreet);
  margin-top: 4px;
}

.momentsCount {
  margin-left: auto;
}

.list {
  margin: 0 -20px;
  position: relative;
  @media (min-width: 600px) {
    margin: 0;
  }
  > * {
    margin-top: calc(-1 * var(--outlineWidth));
    @media (hover: hover) {
      &:hover {
        transition: all 200ms linear;
        border-color: var(--uiInput);
        z-index: 1;
      }
    }
  }
}
