@import "ui/styles/mixins";
.sidebarContainer {
  display: flex;
  margin-top: var(--size-12);
  flex-direction: column;
  justify-content: center;
  max-width: max-content;
}

.top {
  > * {
    margin-right: var(--size-5);
  }
}

.bottom {
  margin-top: var(--size-3);
  display: inline-flex;
  justify-items: center;
  flex-wrap: wrap;
  margin-bottom: 200px;

  svg {
    width: 16px;
    height: 16px;
    margin-left: var(--size-1);
  }
}

.col {
  position: relative;
  margin-right: var(--size-4);
  margin-bottom: var(--size-2);
}

.colActiveContent {
  position: absolute;
  width: 200px;
  top: 24px;
  display: block;
  background-color: var(--textWhite);
  z-index: 1;

  > * {
    margin-bottom: var(--size-1);
  }
}

.listHeader > button > svg {
  transform: scale(1, 1);
  transition: 200ms ease-in;
}

.active > button > svg {
  transform: scale(1, -1);
}

.link {
  animation-duration: 200ms;
  animation-name: fade;
  animation-timing-function: ease;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
