@import "ui/styles/mixins";
.container {
  display:flex; 
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
}

.withPadding {
  padding: var(--size-5);
}