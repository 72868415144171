@import "ui/styles/mixins";
.container {
  padding: var(--size-5);
  border-radius: var(--size-1);
  background-color: var(--uiBoxBackground);
  display: flex;
  align-items: center;
}

.progress {
  flex: 0 0 48px;
}

.details {
  display: flex;
  flex-direction: column;
  margin-left: var(--size-4);
  justify-content: center;
}

.top {
  color: var(--textSecondary);
}

.top,
.name {
  @include limitTextLines(1);
}

.slot {
  margin-left: auto;
  flex: 0 0 auto;
}
