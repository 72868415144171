@import "ui/styles/mixins";
.track {
  display: flex;
  align-items: stretch;
  max-width: 100vw;
  @include scrollingX;
  margin: 1px -20px 0;
  padding-left: var(--size-5);
  padding-right: var(--size-5);
  @media (min-width: 600px) {
    margin: 0;
    padding: 0;
  }
}

.booksHolder {
  width: 100%;
  min-width: max-content;
  display: flex;
  flex-wrap: none;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: -7px;
  a:last-child > div {
    margin-right: 0;
  }
}

.book {
  position: relative;
  width: 72px;
  margin: 0 7px;
}

.desktop {
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
}

.mobile {
  @media (min-width: 900px) {
    display: none;
  }
}

.mobileBookContainer {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .progress {
    margin: 0 7px;
  }
}

.readingProgressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--outlineWidth);
  background-color: var(--accentPrimary);
  transition: all ease-in-out 200ms;
  max-width: 72px;
}

.progress {
  font-size: 12px;
  color: var(--accentPrimary);
}

.hasProgress {
  padding-top: var(--size-1);
  height: 24px;
  width: 100%;
  display: block;
  overflow: hidden;
}
