@import "ui/styles/mixins";
.image {
  margin-bottom: var(--size-6);
  position: relative;
  box-sizing: content-box;
  width: 100%;
  display: flex;
}

.tags {
  position: absolute;
  bottom: var(--size-4);
  right: var(--size-4);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tag {
  background-color: var(--uiBoxBackground);
  padding: 6px 9px;
  border-radius: 60px;
  margin-left: var(--size-2);
}
