@import "ui/styles/mixins";
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.left {
  flex: 1 1 auto;
}

.right {
  margin-left: var(--size-4);
  display: flex;
  align-items: center;
  padding-right: var(--size-2);
  color: var(--uiBlack);
}

.buttons {
  margin-top: var(--size-8);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hideButton {
  padding: 7px 10px;
}
