@import "ui/styles/mixins";
.container {
  position: relative;
  width: 54px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-align: left;

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--uiInput);
    border-radius: 20px;
    margin: 0;
    transition: all 200ms ease-in-out;

    &:focus {
      outline: none;
    }

    > span:focus {
      outline: none;
    }
  }

  .inner {
    display: block;
    width: 200%;
    transform: translateX(-50%);
    transition: margin 100ms ease-in-out;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      color: var(--uiWhite);
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      padding-left: 10px;
      color: var(--uiWhite);
    }
  }

  .disabled {
    background-color: var(--textDisabled);
    cursor: not-allowed;
    &:before {
      background-color: var(--textDisabled);
      cursor: not-allowed;
    }
  }

  .inner:after {
    content: attr(data-no);
    padding-right: 10px;
    color: var(--uiWhite);
    text-align: right;
  }

  .switch {
    display: block;
    width: 18px;
    height: 18px;
    margin: var(--size-1);
    background: var(--uiInput);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 28px;
    border: 0 solid var(--uiInput);
    border-radius: 20px;
    transition: all 200ms ease-in-out;
  }

  &.smallSwitch {
    width: 40px;
    .inner {
      &:after,
      &:before {
        content: '';
        height: 20px;
        line-height: 20px;
      }
    }

    .switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
}

.container {
  input:checked + label {
    .inner {
      transform: translateX(0);
    }
    .switch {
      right: 0px;
      background: var(--accentPrimary);
    }
  }
}

.container:hover {
  .switch {
    background-color: var(--textPrimary);
  }
  .label {
    border-color: var(--uiBlack);
  }
}
