@import "ui/styles/mixins";
.scroller {
  @include scrollingY;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
}

.options {
  margin-bottom: 200px;
}
