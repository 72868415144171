@import "ui/styles/mixins";
.datePicker {
  width: 100%;
  position: relative;
  .label {
    display: inline-block;
    color: var(--textDiscreet);
    position: absolute;
    top: 10px;
    left: 16px;
    &.disabled {
      color: var(--textSecondary);
      z-index: 2;
    }
    @media (min-width: 600px) {
      left: 20px;
    }
  }

  .nativeInput {
    padding-top: 36px;
    min-height: 72px;
    @media (min-width: 600px) {
      padding: 40px 20px 20px 20px;
    }
    &::-webkit-date-and-time-value {
      text-align: left;
    }

    &::placeholder {
      color: var(--textDiscreet);
    }
  }
}
