@import "ui/styles/mixins";
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-4) var(--size-5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--uiWhite);
  z-index: var(--zIndexHeader);
  transform: translateY(-72px);
}

.absolute {
  position: absolute;
  transform: translateY(0);
}

.leftContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
}

.rightContent {
  display: flex;
  align-items: center;
  height: 40px;
}

.invites {
  display: flex;
  align-items: center;
  color: var(--textDiscreet);
}

.inviteText {
  margin-right: var(--size-2);
}

.notificationIcon {
  display: flex;
  align-items: center;
  color: var(--textDiscreet);
}

.unseen {
  color: var(--textBlack);
}

.topDownEntered {
  height: 60px;
  transform: translateY(0);
  border-bottom: 1px solid var(--uiOutline);
  transition: ease 0.2s all;
}

.topDownExited {
  transform: translateY(-82px);
  border-bottom: 1px solid var(--uiOutline);
  transition: ease 0.2s all;
}
