@mixin scrollingY {
  overflow-y: scroll;
  -webkit-overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin scrollingX {
  overflow-x: scroll;
  -webkit-overflow-x: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin scrolling {
  overflow: scroll;
  -webkit-overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}
