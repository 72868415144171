@import "ui/styles/mixins";
.container {
  padding: var(--size-5);
  color: var(--textSecondary);
  width: 100%;
  border-radius: var(--size-1);
  text-align: center;
  background-color: var(--uiBoxBackground);
  margin-bottom: var(--size-10);
}
