@import "ui/styles/mixins";
// This class needs to applied to the wrapping element of ReactDatePicker
.reactDatePicker {
  :global .react-date-picker {
    display: flex;
    position: relative;
  }
  :global .react-date-picker,
  :global .react-date-picker *,
  :global .react-date-picker *:before,
  :global .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  :global .react-date-picker--disabled {
    background-color: var(--uiBoxBackground);
    color: var(--uiSecondary);
  }
  :global .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: var(--outlineWidth) solid var(--uiInput);
    padding-right: 10px;
    padding-top: 20px;
    &:hover,
    &:focus {
      border: var(--outlineWidth) solid var(--textDiscreet);
    }
  }
  :global .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 14px;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.15s linear;
    box-sizing: content-box;
    @media (min-width: 600px) {
      padding: 14px 20px;
    }
  }
  :global .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
    color: var(--textDiscreet);
  }
  :global .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    &::placeholder {
      color: var(--textDiscreet);
    }
  }

  :global .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  :global .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :global .react-date-picker__inputGroup__input:invalid {
    // background: rgba(255, 0, 0, 0.1);
  }
  :global .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  :global .react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    svg {
      color: var(--uiSecondary);
    }
  }
  :global .react-date-picker__button:enabled {
    cursor: pointer;
  }
  :global(.react-date-picker__button:enabled:hover .react-date-picker__button__icon),
  :global(.react-date-picker__button:enabled:focus .react-date-picker__button__icon),
  :global(.react-date-picker__button:enabled:hover svg),
  :global(.react-date-picker__button:enabled:focus svg) {
    color: var(--uiBlack);
  }
  :global .react-date-picker__button:disabled .react-date-picker__button__icon,
  :global .react-date-picker__button:disabled svg {
    color: var(--textDisabled);
  }
  :global(.hasDate .react-date-picker__clear-button svg) {
    display: none;
  }
  :global .react-date-picker__button svg {
    display: inherit;
  }
  :global .react-date-picker__calendar {
    width: 324px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  :global .react-date-picker__calendar--closed {
    display: none;
  }
  :global .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  :global .react-calendar {
    width: 350px;
    max-width: 100%;
    background: var(--uiOverlayBackground);
    border: 0 none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    filter: drop-shadow(0 0 var(--size-2) var(--shadowColor));
    border-radius: 4px;
    will-change: filter;
    z-index: var(--zIndexDropdown);
  }
  :global .react-calendar--doubleView {
    width: 700px;
  }
  :global .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  :global .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  :global .react-calendar,
  :global .react-calendar *,
  :global .react-calendar *:before,
  :global .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  :global .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  :global .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  :global .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
    border-bottom: var(--outlineWidth) solid var(--uiOutline);
    display: flex;
    align-items: center;
  }
  :global .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  :global .react-calendar__navigation button:enabled:hover,
  :global .react-calendar__navigation button:enabled:focus {
    background-color: var(--uiHover);
  }
  :global .react-calendar__navigation button[disabled] {
    color: var(--textDisabled);
    cursor: default;
  }
  :global .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;

    display: none;
  }
  :global .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    display: none;
  }
  :global .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  :global .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  :global .react-calendar__viewContainer {
    padding: 0 16px 8px;
  }
  // :global .react-calendar__month-view__days__day--weekend {
  //   color: #d10000;
  // }
  :global .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--textPrimary);
  }
  :global .react-calendar__year-view .react-calendar__tile,
  :global .react-calendar__decade-view .react-calendar__tile,
  :global .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
  }
  :global .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    background: none;
  }
  :global .react-calendar__tile:disabled {
    color: var(--textDisabled);
    cursor: default;
  }
  :global .react-calendar__tile:enabled:hover,
  :global .react-calendar__tile:enabled:focus {
    background-color: var(--uiHover);
  }
  // :global .react-calendar__tile--now {
  //   color: var(--accentPrimary);
  // }
  // :global .react-calendar__tile--now:enabled:hover,
  // :global .react-calendar__tile--now:enabled:focus {
  //   color: var(--accentPrimary);
  // }
  :global .react-calendar__tile--hasActive {
    background: var(--accentPrimary);
    color: var(--textWhite);
  }
  :global .react-calendar__tile--hasActive:enabled:hover,
  :global .react-calendar__tile--hasActive:enabled:focus {
    background: var(--accentPrimaryHover);
    color: var(--textWhite);
  }
  :global .react-calendar__tile--active {
    background: var(--accentPrimary);
    color: var(--textWhite);
  }
  :global .react-calendar__tile--active:enabled:hover,
  :global .react-calendar__tile--active:enabled:focus {
    background: var(--accentPrimaryHover);
  }
  :global .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--uiHover);
  }
  :global .react-calendar__month-view__days__day {
    aspect-ratio: 1 / 1;
    padding: 10px 0;
    border-radius: 100%;
  }
}
