@import "ui/styles/mixins";
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--uiBackdrop);
  z-index: var(--zIndexModal);
  @media (min-width: 600px) {
    display: none;
  }

  color: var(--uiBlack);
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  animation-duration: 250ms;
  animation-name: fade;
  animation-timing-function: ease;
  display: flex;
  flex-direction: column;
  background-color: var(--uiBackdrop);
}

.inner {
  background-color: var(--uiOverlayBackground);
  max-width: 320px;
  height: 100%;
  margin: 0 54px 0 0;
  padding: var(--size-10) 0 calc(var(--size-10) + env(safe-area-inset-bottom)) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--uiOutline);
  @include scrollingY;

  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 320px;
    height: 92px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--uiWhite) 100%);
    pointer-events: none;
  }
}

.profileDetails {
  padding: 0 var(--size-10);
  flex: 0 0 30vh;
}

.avatarArea {
  margin-bottom: var(--size-5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.name {
  h2 {
    margin-bottom: var(--size-1);
    color: var(--uiBlack);
  }
  color: var(--textDiscreet);
}

.largeLinks {
  padding: var(--size-4) var(--size-9);
  width: 100%;
  a {
    color: var(--textBlack);
  }

  li {
    margin-bottom: var(--size-4);
    display: flex;
    align-items: center;
    color: var(--uiBlack);
    svg {
      width: 32px;
      height: 32px;
      margin-right: var(--size-4);
    }
  }
}

.smallLinks {
  padding: var(--size-4) var(--size-10) 0 var(--size-10);
  width: 100%;
  a {
    color: var(--textPrimary);
  }
  li {
    margin-top: var(--size-4);
  }
}

.optionsEnter {
  transform: translateX(-800px);
}

.optionsEnterActive {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: transform 300ms;
  -webkit-transition: -webkit-transform 300ms;
  transition: ease-in-out transform 300ms;
}

.optionsExit {
  transform: translateX(0);
}

.optionsExitActive {
  -webkit-transform: translateX(-800px);
  transform: translateX(-800px);
  -webkit-transition: transform 300ms;
  -webkit-transition: -webkit-transform 300ms;
  transition: ease-in-out transform 300ms;
}

.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: ease-in opacity 300ms;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: ease-out opacity 300ms;
}
