@import "ui/styles/mixins";
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--uiBoxBackground);
  z-index: var(--zIndexDrawer);
  animation-duration: 200ms;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
}

.inner {
  width: 100%;
  height: calc(100vh - var(--size-5)); // 100 minus margin
  margin-top: var(--size-5);
  box-shadow: 0px 0px 8px var(--shadowColor);
  border-radius: var(--size-1) var(--size-1) 0px 0px;
  background-color: var(--uiOverlayBackground);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  height: 55px;
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  padding: 0 var(--size-5);
  width: 100%;
  > * {
    flex: 1 100%;
  }
  .headerRight {
    display: flex;
    justify-content: flex-end;
  }
  .headerLeft {
    justify-content: flex-start;
  }
  .headerText {
    text-align: center;
    min-width: max-content;
  }
  .headerButton {
    display: flex;
    align-items: center;
    @media (hover: hover) {
      &:hover {
        color: var(--uiBlack);
      }
    }
  }
}

.content {
  height: calc(100% - 55px); // minus the height of the header
  @include scrollingY;
}

// animation classes

.slideEnter {
  -webkit-transform: translateY(800px);
  transform: translateY(800px);
}

.slideEnterActive {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: ease-in -webkit-transform 200ms;
  transition: ease-in transform 200ms;
}

.slideExit {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.slideExitActive {
  -webkit-transform: translateY(800px);
  transform: translateY(800px);
  -webkit-transition: ease-in -webkit-transform 200ms;
  transition: ease-in transform 200ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
