@import "ui/styles/mixins";
.track {
  margin-top: 1px;
  display: flex;
  align-items: stretch;
  margin: 0 -20px;
  padding-left: var(--size-5);
  overflow-y: hidden;
  @include scrollingX;

  @media (min-width: 600px) {
    padding-left: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.goalsHolder {
  width: 80%;
  min-width: max-content;
  display: flex;
  flex-wrap: none;
  justify-content: center;
  align-items: flex-end;
  @media (min-width: 600px) {
    width: 100%;
    flex-direction: column;
    min-width: initial;
  }
}

.fullWidth {
  width: 100%;
}

.createGoal {
  padding-top: var(--size-5);
}

.goal {
  width: 100%;
  max-width: 75vw;
  margin-right: var(--size-2);

  &:last-of-type {
    margin: 0;
  }

  @media (min-width: 600px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: var(--size-2);
  }

  &:only-of-type {
    max-width: 100%;
  }
}

.spacer {
  padding-right: 20px;
}
