@import "ui/styles/mixins";
.container {
  display: flex;
  align-items: center;

  .tag {
    border-radius: var(--size-15);
    min-height: 30px;
    padding: 5px 12px 5px 10px;
    background-color: var(--uiBoxBackground);
    display: flex;
    align-items: center;
    margin-left: var(--size-2);
    @media (min-width: 600px) {
      min-height: 34px;
      padding: 5px 16px 5px 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    .text {
      display: flex;
      align-items: center;
      height: 20px;
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }

    @media (min-width: 600px) {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
