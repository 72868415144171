@import "ui/styles/mixins";
.outer {
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  padding-bottom: var(--size-10);
  margin-bottom: var(--size-10);
  width: 100%;
}
.container {
  background-color: var(--uiBoxBackground);
  border-radius: var(--size-1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--size-4);
  color: var(--textSecondary);
}
