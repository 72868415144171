// Small iPhone 5 screens
@mixin max350 {
  @media screen and (max-width: 350px) {
    @content;
  }
}

// Larger than regular mobile
@mixin min600 {
  @media screen and (min-width: 600px) {
    @content;
  }
}

// Regular mobile
@mixin max600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}

// Large mobiles, i.e. iPad
@mixin min768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin max768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}

// Medium breakpoint
@mixin min900 {
  @media screen and (min-width: 900px) {
    @content;
  }
}

// Self explanatory

@mixin max900 {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin min1120 {
  @media screen and (min-width: 1120px) {
    @content;
  }
}

@mixin max1120 {
  @media screen and (max-width: 1120px) {
    @content;
  }
}
