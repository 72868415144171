@import "ui/styles/mixins";
.container {
  padding: var(--size-6);
  background-color: var(--uiBoxBackground);
  border-radius: var(--size-1);
  display: flex;
  justify-content: space-between;
}

.left {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  text-align: right;
}

.headline {
  font-size: 14px;
  color: var(--textSecondary);
  margin-bottom: var(--size-1);
}

.avatars {
  width: 123px;
  height: 95px;
  margin-right: var(--size-3);
  position: relative;
}
.avatarHolder {
  position: absolute;
  &:nth-child(1) {
    width: 40px;
    height: 40px;
    left: 49px;
    top: 12px;
  }
  &:nth-child(2) {
    width: 36px;
    height: 36px;
    left: 33px;
    bottom: 0;
  }
  &:nth-child(3) {
    width: 30px;
    height: 30px;
    right: 0;
    top: 45px;
  }
  &:nth-child(4) {
    width: 27px;
    height: 27px;
    top: 33px;
    left: 0;
  }
  &:nth-child(5) {
    width: 24px;
    height: 24px;
    top: 0;
    left: 18px;
  }
}

.sticker {
  position: absolute;
  bottom: -5%;
  right: -5%;
  width: 42.5%;
  height: 42.5%;
  background-color: var(--accentPrimary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--outlineWidth) solid var(--uiWhite);
  svg {
    width: 100%;
    height: 100%;
    color: var(--uiWhite);
  }
}
