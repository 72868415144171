@import "ui/styles/mixins";
.autoUpdate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-5);
}

.dates {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  div {
    &:hover {
      z-index: 1;
    }
  }
}

.updateInfo {
  margin-top: var(--size-1);
  color: var(--textDiscreet);
}

.delete {
  width: 100%;
  margin-top: var(--size-10);
  border-top: var(--outlineWidth) solid var(--uiOutline);
  padding-top: var(--size-6);
}

.button {
  margin-left: auto;
  width: 100%;
  display: flex;
  margin-top: var(--size-5);
  justify-content: flex-end;
}
.datePickerLeft,
.datePickerRight {
  flex: 1 1 50%;
  margin-right: -1px;
}
