@import "ui/styles/mixins";
.container {
  margin-top: var(--size-2);

  @include min600 {
    margin-top: var(--size-14);
  }
}
.bookCount {
  display: none;
  @media (min-width: 600px) {
    display: flex;
  }
}
