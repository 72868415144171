@import "ui/styles/mixins";
.inputHolder {
  width: 200px;
  height: 200px;
  background-image: url('/img/goalprogressillustrative.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: var(--size-5) auto var(--size-14) auto;
  @media (min-width: 600px) {
    margin-top: var(--size-18);
  }
  input {
    margin-top: var(--size-14);
    width: 100%;
    border: none;
    font-size: 54px;
    background-color: transparent;
    text-align: center;
    line-height: 1.3;
    &::placeholder {
      font-size: 54px;
      color: var(--textDisabled);
    }
    &:placeholder-shown {
      text-align: left;
      padding-left: 42%;
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
